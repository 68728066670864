import './App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { Job } from './components/Job';
import { useEffect, useState } from 'react';
import { Modal } from './components/Modal';

function App() {
  const [jobs, setJobs] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    let API_URL = "https://cv-backend-pi.vercel.app"
    const fetchJobs = async () => {
      try {
        const response = await fetch(API_URL + '/api/jobs')
        const json = await response.json()
        if (response.ok){
          setJobs(json)
          setError(false)
        } else {
          setError(true)
        }
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }

    }
    fetchJobs();
  }, [])
  const showModal = () => {
    document.querySelector(".modal").style.display = "block";
  }
  return (
    <div className="App">
      <header>
        <h1>Julian Sznaider</h1>
        <h2>Software Developer</h2>
      </header>
      <div className="body">
        <div className="sidebar">
          {/*  Intro */}
          <section id="intro">
            <h3>Introduction</h3>
            <p>I love challenges, innovation, and building strong relationships. 
I need to learn at least one new thing every day. I see technology as the engine to boost any business.</p>
          </section>

          {/*  Contact */}
          <section id="contact">
            <h3>Contact</h3>
            
            <div>
              <FontAwesomeIcon icon={faLinkedin} />
              <span><a href="https://linkedin.com/in/juliandev" target="_blank" rel='noreferrer'>juliandev</a></span>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} />
              <span rel='noreferrer' className="contact-button" onClick={ showModal }>Contact me</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faLocationCrosshairs} />
              <span>Buenos Aires, Argentina</span>
            </div>
          </section>

          {/*  Education */}
          <section id="education">
            <h3>Education</h3>
            <ul className="timeline">
              <li>
                <h5>Palermo University</h5>
                <span className="years">2014 - 2017</span>
                <span className="title">Bachelor's Degree in IT and Management</span>
              </li>
              <li>
                <h5>ORT</h5>
                <span className="years">2010 - 2013</span>
                <span className="title">Systems Analyst</span>
              </li>
            </ul>
          </section>

          {/*  Technologies */}
          <section id="technologies">
            <h3>Technologies</h3>
            <ul>
              <li>
                <h4>Languages - frameworks - libraries</h4>
                <div>
                <strong>PHP - JavaScript - Laravel - HTML <br /> CSS</strong> - React - NodeJS - Express
                </div>
              </li>
              <li>
                <h4>Databases</h4>
                <div>
                  <strong>MySQL</strong> - PostgreSQL - Oracle - MSSQL - MongoDB
                </div>
              </li>
              <li>
                <h4>Infrastructure</h4>
                <div>AWS - Linux - Docker</div>
              </li>
              <li>
                <h4>Methodologies</h4>
                <div>Agile - Scrum - Kanban - PMI</div>
              </li>
            </ul>
          </section>

          {/*  Skills */}
          <section id="skills">
            <h3>Soft skills</h3>
            <ul className="bullets">
              <li>Handle internal / external clients</li> 
              <li>Handle external providers</li>
              <li>People management</li>
              <li>Project management</li>
            </ul>
          </section>
        </div>

        <section id="jobs">
          <h3>Experience</h3>
          
            { loading ? <div className="feedback"><div className="loader"></div></div>: ''}
            { error ? <div className="feedback"><span>Oops... something went wrong on the server side. <br />Retry or refer to the offline CV or <a href="https://linkedin.com/in/juliandev" target="_blank" rel='noreferrer'>LinkedIn</a>.</span></div> : '' }
          
          <ul className="timeline">
            { jobs && jobs.sort((a, b) => { return b.order - a.order }).map((job) => (
                <Job key={job.order} jobData={job}></Job> 
              )
            )}
          </ul>
        </section>
      </div>
      <Modal />
    </div>
  );
}

export default App;
