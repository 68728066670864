import { useState } from "react";

export const Modal = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [feedback, setFeedback] = useState('')
    
    let modal = document.querySelector(".modal")
    const hide = () => {
        modal.style.display = "none";
    }
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    }
    const submit = async () => {
        const API_URL = "https://cv-backend-pi.vercel.app"
        const data = { name, email, message }
        const res = await fetch(API_URL + '/api/email/send', {
            method: 'POST', 
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        await res.json()
        if (res.ok){
            setFeedback("Your message has been sent, I will get in touch soon.")
        }else{
            setFeedback("Oops... something went wrong on the server side, please refer to the offline CV or <a href='https://linkedin.com/in/juliandev' target='_blank' rel='noreferrer'>LinkedIn</a>.")
        }
    }
    return (
        <div className="modal">
            <div className="content">
                <span className="close" onClick={hide}>&times;</span>
                <form className={"form " + (feedback ? ' hidden' : '') }>
                    <div className="formItem">
                        <label>Name</label>
                        <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="formItem">
                        <label>Email</label>
                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="formItem">
                        <label>Message</label>
                        <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className="actions">
                        <button type="button" onClick={submit}>Submit</button>
                    </div>
                </form>
                <div className={"modal-feedback " + (!feedback ? ' hidden' : '') } dangerouslySetInnerHTML={{ __html: feedback }}></div>
            </div>
        </div>
    )
}