import { useState } from "react"

export const Job = ({jobData}) => {
    const [open, setOpen] = useState(false)
    let excerpt = jobData.description.split(' ').slice(0, 30).join(' ')
    return (
        <li>
            <h5>{jobData.title}</h5>
            <div className="subtitle">
                <span className="company">
                {jobData.company}
                </span>
                <span className="period">{jobData.period}</span>
            </div>
            { open ? 
                <div className="details"><span dangerouslySetInnerHTML={{ __html: jobData.description }} /> <i onClick={() => setOpen(false)}>Show less</i></div>
                :
                <div className="details"><span dangerouslySetInnerHTML={{ __html: excerpt }} /> [...] <i onClick={() => setOpen(true)}>Show more</i></div>
            }
        </li>
    )
}